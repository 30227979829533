<template>
  <el-card class="container">
    <h1><i class="frame"></i> 活 动</h1>
    <div>
      <div class="progres">
        <div v-if="activeList.length == 0">
          <el-empty
            description="该班级还没有活动!"
            style="padding-top: 70px"
          ></el-empty>
        </div>
        <el-card
          class="list"
          v-for="(item, index) in activeList"
          :key="index"
          v-if="index < activeListLength"
          shadow="hover"
        >
          <span @click="clickActive(item)">
            <div class="chart-div" v-if="item.isStart != 2">
              <v-chart
                ref="refchart"
                :options="echarts(item)"
                autoresize
                class="chart"
                style="height: 100%"
              />
            </div>

            <div class="list-title">
              <span class="list_icon">{{
                item.stemType == 0 ? "K12" : item.stemType == 1 ? "IMX" : "标签"
              }}</span>
              {{ item.title }}
            </div>

            <p>{{ item.completion }}%</p>
            <p
              class="state state-n"
              v-if="item.isStart == 3 && item.completion < 100"
            >
              已结束
            </p>
            <p
              class="state state-s"
              v-if="item.completion < 100 && item.isStart == 1"
            >
              进行中
            </p>
            <p class="state state-a" v-if="item.completion >= 100">已完成</p>
            <p class="state" v-if="item.isStart == 2">未开启</p>
          </span>
        </el-card>
      </div>
    </div>
  </el-card>
</template>

<script>
import { activeList } from "../../api/api";
// import * as echarts from 'echarts'
import ECharts from "vue-echarts";
import LabelGroupVue from "../../views/materialGather/LabelGroup.vue";
export default {
  components: {
    "v-chart": ECharts,
  },
  props: ["selectClassId"],
  data() {
    return {
      activeList: [],
      activeListLength: 6,
      status: 0, //0  老师  1  学生
    };
  },
  watch: {
    selectClassId(val, old) {
      if (val !== old) {
        this.loadActive();
      }
    },
  },
  created() {},
  mounted() {
    this.analysis();
  },
  methods: {
    // 解析appIds
    analysis() {
      if (this.utils.identity("K12做题")) {
        this.status = 1;
      }
    },
    echarts(item) {
      var yes = item.completion;
      var no = 0;
      var colors = ["#488AFF", "#EFF3F5"];
      if (item.completion == 100) {
        no = 0;
      } else {
        no = 100 - yes;
      }
      // if (yes == 0) {
      //   colors[1] = "#FFF";
      // }
      return {
        tooltip: {
          trigger: "item",
        },
        color: colors,
        series: [
          {
            name: "任务状态",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: yes, name: "已完成" },
              { value: no, name: "未完成" },
            ],
          },
        ],
      };
    },
    // 点击跳转活动
    clickActive(val) {
      if (val.isStart == 2) {
        this.$message.error("该活动未开始!");
        return;
      }
      this.$router.push({
        path: "/activityDetail",
        query: { id: val.id, stemType: val.stemType, classId: val.classId },
      });
    },
    loadActive() {
      let param = {
        pageNum: null,
        pageSize: null,
        classId: this.selectClassId,
        stemType: null, //题库类型  0:k12  1:immx
      };
      activeList(param).then((res) => {
        if (res.data) {
          let unfinished_arr = [];
          res.data.list.map((item) => {
            if (this.status == 0) {
              if (item.completeUser > 0) {
                let a = (item.completeUser / item.allUser).toFixed(2);
                item["completion"] = Math.round(a * 100);
              } else {
                item["completion"] = 0;
              }
            } else {
              if (item.completeTask > 0) {
                let a = (item.completeTask / item.allTask).toFixed(2);
                item["completion"] = Math.round(a * 100);
              } else {
                item["completion"] = 0;
              }
            }
            // if (item.isStart == 1) {
            unfinished_arr.push(item);
            // }
          });
          this.activeList = unfinished_arr;
          this.$emit("activeList", this.activeList);
        }
        let arr = [
          {
            value: 0,
            name: "已完成",
          },
          {
            value: 0,
            name: "进行中",
          },
          {
            value: 0,
            name: "未开始",
          },
          {
            value: 0,
            name: "已结束",
          },
        ];
        let arr1 = [];
        let arr2 = [
          {
            value: 0,
            name: "全部",
          },
        ];
        let arr3 = [
          {
            value: 0,
            name: "k12",
          },
          {
            value: 0,
            name: "imx",
          },
          {
            value: 0,
            name: "标签",
          },
          {
            value: 0,
            name: "视频",
          },
          {
            value: 0,
            name: "试卷",
          },
        ];
        arr2[0].value = this.activeList.length;
        this.activeList.forEach((item) => {
          let date = this.currentTime.currentTime(); //当前时间
          item.startTime = item.startTime.split(" ");
          item.endTime = item.endTime.split(" ");
          if (date < item.startTime[0]) {
            item.isStart = 2;
          }
          if (date > item.endTime[0]) {
            item.isStart = 3;
          }
          if (item.isStart == 2) {
            arr[2].value = arr[2].value + 1;
          } else if (item.isStart == 3 && item.completion < 100) {
            arr[3].value = arr[3].value + 1;
          } else if (item.completion !== 100 && item.isStart == 1) {
            arr[1].value = arr[1].value + 1;
          } else {
            arr[0].value = arr[0].value + 1;
          }
          //任务类型
          if (item.type == 0) {
            arr3[0].value = arr3[0].value + 1;
          } else if (item.type == 1) {
            arr3[1].value = arr3[1].value + 1;
          } else if (item.type == 2) {
            arr3[2].value = arr3[2].value + 1;
          } else if (item.type == 3) {
            arr3[3].value = arr3[3].value + 1;
          } else if (item.type == 4) {
            arr3[4].value = arr3[4].value + 1;
          }
        });
        arr1.push(arr);
        arr1.push(arr3);
        //0是k12  1 imx  2 标签  3 视频  4 试卷

        this.$emit("selectActive", arr1);
      });
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../style/mouse.less";
.frame {
  border-left: 4px solid #397cff;
  margin-right: 5px;
}
.container {
  background: #fff;
  padding: 20px;
  h1 {
    font-size: 20px;
    font-weight: bold;
  }
  .progres {
    .title {
      display: flex;
      margin: 0 20px;
      line-height: 55px;
      font-weight: bold;
      .left {
        width: 50%;
      }
    }
    .list {
      position: relative;
      margin-top: 10px;
      display: flex;
      display: inline-block;
      line-height: 38px;
      padding: 0 20px;
      width: 32%;
      border: none;
      margin-right: 2%;
      border: 1px solid rgb(241, 241, 241) !important;
      /deep/.el-card__body {
        padding: 0 20px;
      }
      .list-title {
        margin-top: 4%;
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        color: #515a6e;
        line-height: 20px;
      }
      // .right {
      //   margin-left: 10px;
      //   display: inline-block;
      //   overflow: hidden;
      // }
      // .el-progress {
      //   margin-top: 10px;
      //   width: 50%;
      //   display: inline-block;
      //   overflow: hidden;
      //   margin-bottom: 12px;
      // }
      p {
        width: 48px;
        height: 42px;
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0b1b31;
        line-height: 42px;
        margin-bottom: 15px;
        margin-top: 15px;
      }
      .list_icon {
        width: 45px;
        height: 25px;
        background: #e3edff;
        color: #397cff;
        display: inline-block;
        line-height: 25px;
        text-align: center;
        border-radius: 5px;
        margin-right: 15px;
        border-radius: 3px;
        border: 1px solid #397cff;
      }
      .state {
        width: 36px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffb95a;
        line-height: 17px;
      }
      .state-s {
        color: #5ad8a6;
      }
      .state-a {
        color: #596275;
      }
      .state-n {
        color: #e8684a;
      }
    }
    .list:nth-child(3n + 3) {
      margin-right: 0;
    }
    .chart {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .chart-div {
      width: 100px;
      height: 100px;
      position: absolute;
      right: 30px;
      top: 19px;
    }
  }
}
</style>
