<template>
  <div class="homePage">
    <div class="screen" style="display: flex; align-items: center">
      <span class="text">选择班级</span>
      <div class="select">
        <el-select
          v-model="classValue"
          class="sel"
          size="mini"
          @change="selectClass"
        >
          <el-option
            v-for="item in classOptions"
            :key="item.id"
            :label="item.className"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <!-- 活动列表 -->
    <div class="module_t">
      <progress-Home
        @selectActive="selectActive"
        @activeList="activeList"
        :selectClassId="selectClassId"
      ></progress-Home>
    </div>
    <div class="module_b" >
      <div class="module_b_l module_b_r" style="width: 100%">
        <div class="content bar ba">
          <h3> <i class="frame"></i>  不同活动完成率分析</h3>
          <el-empty
            description="暂无完成的活动!"
            v-if="showK12conversion"
            style="padding-top: 70px"
          ></el-empty>
          <div
            ref="K12conversion"
            :style="{ width: '100%', height: '100%' }"
            v-if="!showK12conversion"
          ></div>
        </div>
      </div>
    </div>
    <div class="module_b">
      <div class="module_b_l module_b_r">
        <div class="content pic" style="height: 95%">
          <h3>任务进度状态</h3>
          <v-chart
            ref="refchart"
            :options="pieOptions"
            autoresize
            class="chart"
            style="height: 100%"
          />
         
        </div>
      </div>
      <div class="module_b_l module_b_r">
        <div class="content bar">
          <h3>活动类型占比图</h3>
          <el-empty
            description="暂无完成的活动!"
            v-if="showK12conversion"
            style="padding-top: 70px"
          ></el-empty>
              <v-chart v-if="!showK12conversion" ref="refchart" :options="pieOptionsType" autoresize class='chart' style="height:100%;"/>
        </div>  
      </div>
    </div>
      <div class="module_b">
      <div class="module_b_l module_b_r" style="width: 100%">
        <div class="content pic">
          <h3><i class="frame"></i> 资料及讨论</h3>
          <el-empty description="该班级还没有人评论!" v-if="comments.length==0" style="padding-top:70px"></el-empty>
          <div class="des" v-for="(item,index) in comments" :key="index" @click="commentsDetails(item)">
            <div class='avatar'>
              <el-avatar> {{item.headPortrait}} </el-avatar>
              <span class="name">{{item.userName}}</span>
            </div>
            <div>
              <h4>{{item.activeName}}</h4>
              <p class="info">{{item.commentMessage}}
                <ul class="date">
                  <li><i class="el-icon-chat-dot-round"></i>{{item.replyNumber}}</li>
                  <li>{{item.createTime}}</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { activeList } from "../../api/api";
import { classList } from "../../api/api";
import { activeComment } from "../../api/api";
import { IntegralStatistics } from "../../api/api";
import { ActivityPercentageList } from "../../api/api";
import progressHome from "@/components/progress";
import ECharts from "vue-echarts";
import * as echarts from "echarts";
export default {
  components: {
    progressHome,
    "v-chart": ECharts,
  },
  data() {
    return {
      pagesize: 5,
      currentPage: 1,
      currentPageIntegral: 1,
      integralDataTotal: null,
      classOptions: [],
      integralData: [],
      classValue: null,
      pieOptions: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c}",
        },
        colors: ["#5B8FF9", "#5AD8A6", "#FFB95A", "#e8684a"],

        legend: {
          bottom: "1%",
          left: "center",
        },
        series: [
          {
            name: "任务状态",
            type: "pie",
            radius: ["45%", "65%"],
            data: [],
          },
        ],
      },
      pieOptionsType: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c}",
        },
        colors: ["#5B8FF9", "#5AD8A6", "#FFB95A", "#e8684a"],

        legend: {
          bottom: "1%",
          left: "center",
        },
        series: [
          {
            name: "任务状态",
            type: "pie",
            radius: ["45%", "65%"],
            data: [],
          },
        ],
      },
      comments: [],
      showComments: false,
      selectClassId: null,
      K12chart: null,
      K12Histogram: null,
      showText: false,
      showK12conversion: false,
      k12Echarts1: [],
      k12Echarts2: [],
      appIdUser: null,
      loading: true,
    };
  },
  created() {
    this.analysis();
  },
  mounted() {
    this.loadClass();
    this.$nextTick(() => {
      this.$refs["refchart"].chart.on("click", (params) => {
        if (params.name == "已结束") {
          params.name = "已完成";
        }
        this.$router.push({
          path: "/activity",
          query: { idType: params.name, classId: this.selectClassId },
        });
      });
    });
  },
  methods: {
    // 解析appIds
    analysis() {
      if (!this.utils.identity("展示首页")) {
        this.$router.push("/classRoom");
      }
    },
    // 显示活动图表
    activeList(val) {
      if (val.length > 0) {
        let arr = [];
        let arr1 = [];
        val.map((item, index) => {
          if (index < 6) {
            arr.push(item.completion);
            arr1.push(item.title);
          }
        });
        this.k12Echarts1 = arr;
        this.k12Echarts2 = arr1;
        this.showK12conversion = false;
        setTimeout(() => {
          this.K12Echarts(arr, arr1);
        }, 400);
      } else {
        this.showK12conversion = true;
      }
    },
    // 子组件传环形图数据
    selectActive(val) {
      this.pieOptions.series[0].data = val[0];
      this.pieOptionsType.series[0].data = val[1];
    },
    // 选中班级
    selectClass(val) {
      this.currentPageIntegral = 1;
      this.selectClassId = val;
      this.loadComment();
      this.loadK12Histogram(val);
    },
    // 获取班级
    async loadClass() {
      this.loading = true;
      await classList({}).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          this.classOptions = res.data.list;
          if (this.classOptions.length > 0) {
            this.classValue = this.classOptions[0].id;
            this.loadComment();
          }
          this.selectClassId = this.classValue;
          this.loadK12Histogram(this.selectClassId);
        }
      });
    },
    // 获取评论
    loadComment() {
      activeComment({
        activeId: null,
        pageNum: 1,
        pageSize: 4,
        orderType: 1, //热度倒序
        classId: this.classValue,
      }).then((res) => {
        if (res.data) {
          res.data.list.map((item) => {
            item["replyNumber"] = item.replyList.length;
            item["label"] = "";
            item["headPortrait"] = item.userName[0];
          });
          this.comments = res.data.list;
          if (this.comments.length == 0) {
            this.showComments = true;
          } else {
            this.showComments = false;
          }
        }
      });
    },
    // 评论跳转
    commentsDetails(val) {
      this.$router.push({
        path: "/activityDetail",
        query: { id: val.activeId, classId: this.classValue },
      });
    },
    // 获取K12的柱状图数据
    loadK12Histogram(val) {
      let param = {
        id: val,
        startTime: this.getDay(-7) + " " + "00:00:00", //时间范围
        endTime: this.getDay(0) + " " + "23:59:59",
      };
      ActivityPercentageList(param).then((res) => {
        res.data.reverse();
        this.K12Histogram = res.data;
        if (res.data.length > 0) {
          this.showText = false;
        } else {
          setTimeout(() => {}, 300);
          this.showText = true;
        }
      });
    },
    K12Echarts(arr, arr1) {
      if (this.$refs.K12conversion) {
        this.K12chart = this.$echarts.init(this.$refs.K12conversion);
        var option;
        option = {
          xAxis: {
            type: "category",
            data: arr1,
          },
          yAxis: {
            type: "value",
            data: [100],
            max: 100,
          },
          series: [
            {
              barWidth: 30, //柱图宽度
              barMaxWidth: 130,
              data: arr,
              type: "bar",
            },
          ],
        };
        this.K12chart.setOption(option);
      }
    },
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
  },
};
</script>
<style lang='less' scoped>
@import "./style/index.less";
@import "../../style/mouse.less";
</style>